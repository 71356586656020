import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import backend from "../../functions/backend";
import { useCheckResponseFail } from "../../hooks/useCheckResponseFail";
import { prettyPrintDateTime } from "../../functions/timeDate";
import { setupStepString } from "./stat";
import { getMoneyFlowToken } from "../../state/stateApplication";
import { Table, Layout } from "antd";

const { Content } = Layout;

// Works for Unicode characters.
// Ignores case and diacritics.
// Currently hardcoded to 'en' language.
const alphabeticalSort = (a, b) => {
  return a.localeCompare(b, "en", { sensitivity: "base" });
};

// Cycle between 'ascend' and 'descend'.
// The last 'ascend' is to tell Ant, not to cycle back to 'no sort'.
const ASCEND_DESCEND = ["ascend", "descend", "ascend"];

const columns = [
  /*
  {
    title: "Username",
    dataIndex: "username",
    key: "username",
    sorter: (a, b) => alphabeticalSort(a.username, b.username),
    sortDirections: ASCEND_DESCEND,
  },
  */
  {
    title: "First Name",
    dataIndex: "first_name",
    key: "firstName",
    sorter: (a, b) => alphabeticalSort(a.first_name, b.first_name),
    sortDirections: ASCEND_DESCEND,
    width: "10em",
  },
  {
    title: "Last Name",
    dataIndex: "last_name",
    key: "lastName",
    sorter: (a, b) => alphabeticalSort(a.last_name, b.last_name),
    sortDirections: ASCEND_DESCEND,
    width: "10em",
  },
  {
    title: "Email Address",
    dataIndex: "email_address",
    key: "email",
    sorter: (a, b) => alphabeticalSort(a.email_address, b.email_address),
    sortDirections: ASCEND_DESCEND,
  },
  {
    title: "Last Login",
    dataIndex: "last_login",
    key: "lastLogin",
    //sorter: (a, b) => alphabeticalSort(a.email_address, b.email_address),
    //sortDirections: ASCEND_DESCEND,
    render: (last_login) => {
      return <p>{prettyPrintDateTime(last_login)}</p>;
    },
    width: "10em",
  },
  {
    title: "Created Account",
    dataIndex: "created_datetime",
    key: "createdDatetime",
    //sorter: (a, b) => alphabeticalSort(a.email_address, b.email_address),
    //sortDirections: ASCEND_DESCEND,
    render: (created_datetime) => {
      return <p>{prettyPrintDateTime(created_datetime)}</p>;
    },
    width: "10em",
  },
  /*
  {
    title: "Last Changed",
    dataIndex: "last_changed_datetime",
    key: "lastChangedDatetime",
    //sorter: (a, b) => alphabeticalSort(a.email_address, b.email_address),
    //sortDirections: ASCEND_DESCEND,
    render: (last_changed_datetime) => {
      return <p>{prettyPrintDateTime(last_changed_datetime)}</p>;
    },
  },
  */
  {
    title: "Farthest Setup Step",
    dataIndex: "money_wheel_setup_step",
    key: "moneyWheelSetupStep",
    //sorter: (a, b) => alphabeticalSort(a.email_address, b.email_address),
    //sortDirections: ASCEND_DESCEND,
    render: (money_wheel_setup_step) => {
      return <p>{setupStepString(money_wheel_setup_step)}</p>;
    },
    width: "10em",
  },
  {
    title: "Setup Complete?",
    dataIndex: "is_setup_complete",
    key: "isSetupComplete",
    //sorter: (a, b) => alphabeticalSort(a.email_address, b.email_address),
    //sortDirections: ASCEND_DESCEND,
    render: (is_setup_complete) => {
      return <p>{is_setup_complete ? "Y" : "N"}</p>;
    },
    width: "5em",
  },
  {
    title: "Last Monthly Review",
    dataIndex: "last_review",
    key: "lastReview",
    //sorter: (a, b) => alphabeticalSort(a.email_address, b.email_address),
    //sortDirections: ASCEND_DESCEND,
    width: "5em",
  },
];

const ROWS_PER_PAGE = 10;

export default function AdminUsersStatContent() {
  const navigate = useNavigate();
  const checkResponseFail = useCheckResponseFail();
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: ROWS_PER_PAGE,
    total: 0,
  });

  useEffect(() => {
    if (getMoneyFlowToken() === "") {
      navigate("/", { replace: true });
    } else {
      getUser(pagination.current, pagination.pageSize);
    }
  }, []);

  const handleShowSizeChange = (currentPage, pageSize) => {
    console.log(
      `handleShowSizeChange() currentPage=${currentPage}, pageSize=${pageSize}`,
    );
    setPagination((prev) => ({
      ...prev,
      current: currentPage,
      pageSize: pageSize,
    }));
    getUser(currentPage, pageSize);
  };

  // Handle table pagination changes
  const handleTableChange = (newPage) => {
    console.log(`handleTableChange() newPage=${newPage}`);
    setPagination((prev) => ({
      ...prev,
      current: newPage,
    }));
    getUser(newPage, pagination.pageSize);
  };

  async function getUserSettings(userId) {
    const response = await backend.get(
      "/v1/money_wheel/settings?user_id=" + userId,
    );
    if (
      checkResponseFail(
        response,
        `Failed to GET money_wheel/settings: user_id=${userId}`,
      )
    )
      return null;
    console.log(`getUserSettings() for userId=${userId}`);
    console.log(response);
    if (response.items && response.items.length) {
      return response.items[0]; // settings object
    }
    return null;
  }

  async function getUser(page, pageSize) {
    console.log(`getUSER: 0  page =${page}`);
    setLoading(true);
    const totalUsers = await getNumUsers();
    console.log(`totalUsers =${totalUsers}`);

    // Get the next page of users
    const response = await backend.get(
      `/v1/user?_offset=${pageSize * (page - 1)}&_limit=${pageSize}`,
    );
    if (checkResponseFail(response, `Failed to GET users: _offset=${page}`))
      return 0;
    if (response.items) {
      console.log(response.items);
      let key = 0;

      // for each user on the page, get additional info
      for (const user of response.items) {
        user.key = key;
        key++;
        const moneyWheelSettings = await getUserSettings(user.id);
        console.log(`getUSER: user.id = ${user.id}`);
        console.log(moneyWheelSettings);
        if (moneyWheelSettings) {
          user.money_wheel_setup_step = moneyWheelSettings.setup_step;
          user.is_setup_complete = moneyWheelSettings.is_setup_complete;
          user.last_review = `${moneyWheelSettings.last_review_year}/${moneyWheelSettings.last_review_month}`;
          if (user.last_review === "2001/1") user.last_review = "none";
        } else {
          user.money_wheel_setup_step = "none";
          user.is_setup_complete = false;
          user.last_review = "none";
        }
      }

      setDataSource(response.items);
      setPagination((prev) => ({
        ...prev,
        total: totalUsers, // total number of records
      }));
      console.assert(
        response.items.length <= pageSize,
        "Error: getUser(): unexpected number of response.items",
      );
    }
    setLoading(false);
  }

  async function getNumUsers() {
    const response = await backend.get(`/v1/user?_count=1`); // get all users
    if (checkResponseFail(response, "Failed to GET number of users:")) return 0;
    return response.count || 0;
  }

  return (
    <Content
      style={{
        margin: "24px 16px 0",
        minHeight: "75vh",
        overflow: "initial",
      }}
    >
      <div
        className="site-layout-background"
        style={{ padding: 24, textAlign: "left" }}
      >
        <h1>User Activity</h1>
        <Link to="/admin">Back to Admin Dashboard</Link>
      </div>
      <Table
        loading={loading}
        dataSource={dataSource}
        columns={columns}
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: pagination.total, // total number of rows
          showSizeChanger: true,
          onShowSizeChange: handleShowSizeChange,
          onChange: handleTableChange,
          position: ["topRight", "bottomRight"], // where pagination component appears
        }}
        rowKey="id" // unique id for the row
      />
    </Content>
  );
}
